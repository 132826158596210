// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commenti-alle-letture-domenicali-js": () => import("./../../../src/pages/commenti-alle-letture-domenicali.js" /* webpackChunkName: "component---src-pages-commenti-alle-letture-domenicali-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meditazioni-e-approfondimenti-js": () => import("./../../../src/pages/meditazioni-e-approfondimenti.js" /* webpackChunkName: "component---src-pages-meditazioni-e-approfondimenti-js" */),
  "component---src-pages-storia-del-santuario-js": () => import("./../../../src/pages/storia-del-santuario.js" /* webpackChunkName: "component---src-pages-storia-del-santuario-js" */),
  "component---src-templates-approfondimenti-page-template-js": () => import("./../../../src/templates/approfondimentiPageTemplate.js" /* webpackChunkName: "component---src-templates-approfondimenti-page-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */)
}

